/*
  core-js and regenerator-runtime should be included via .babelrc
  https://babeljs.io/docs/en/babel-preset-env
  [
    "@babel/preset-env", {
      "useBuiltIns": "usage", << should read from .browserslistrc
      "corejs": 3 << and inclucde all the core-js stuff for those browsers
                     including regenerator-runtime
    }
  ]
*/

// window.fetch
import "whatwg-fetch";
